import { Link } from "gatsby";
import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";
import Icon from "./icon";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/project-details.module.css";

function ProjectDetails(props) {

  const {
    title,
    subtitle,
    _rawContent,
    link,
    image
  } = props;

  return (
    <div className={styles.root}>
      {title && (
        <div className={styles.titleWrap}>
          {subtitle && (
            <div className={styles.subtitle}>{subtitle}</div>
          )}
          
          <h3 className={cn(styles.title, title === "lululemon" && styles.lc)}>{title}</h3>

          {image && image.asset && (
            <div className={styles.image}>
              <img
                src={imageUrlFor(buildImageObj(image))
                  .auto("format")
                  .url()}
                alt={image.alt}
              />
            </div>
          )}
        </div>
      )}
      {_rawContent && (
        <div className={styles.content}>
          <PortableText blocks={_rawContent} />
        </div>
      )}
      {link && link.linkUrl && (
        <p>
        {link.externalContent ? 
          (
            <a className={styles.link} href={link.linkUrl} target="_blank" rel="noreferrer">{link.text}<Icon symbol="external" /></a>
          ) : (
            <Link className={styles.link} to={`${link.linkUrl}`}>{link.text}</Link> 
          )
        }
        </p>
      )}
    </div>
  );
}

export default ProjectDetails;
