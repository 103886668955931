import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Container from "./container";
import ProjectDetails from "./project-details";
import Masonry from 'react-masonry-css';

import * as styles from "../styles/components/creating-projects.module.css";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ProjectsQuery {
    projects: allSanityProject(
      sort: { fields: [order], order: ASC }
      filter: { title: { ne: "Chip Wilson" } }
    ) {
      edges {
        node {
          id
          title
          subtitle
          _rawContent
          image {
            ...SanityImage
            alt
          }
          link {
            text
            linkUrl
            externalContent
          }
        }
      }
    }
  }
`;

const Projects = (props) => {

  const {
    title
  } = props;

  const breakpointColumnsObj = {
    default: 2,
    767: 1
  };

  return (
    <StaticQuery
    query={query}
    render={data => (
      <div className={styles.root}>
        <Container>
          {title && (
            <h2 className={styles.heading} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">{title}</h2>
          )}
          {data && (
            <div className={styles.projectsWrap}>
              <div className={styles.containerContent}>
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className={styles.projects}
                  columnClassName={styles.projectsCol}>
                  {data.projects.edges.map(({node: project}) => (
                    <div className={styles.item} key={project.id}>
                      <ProjectDetails {...project} />
                    </div>
                  ))}
                </Masonry>
              </div>
            </div>
          )}
        </Container>
      </div>
    )} />
  );
}

export default Projects;
