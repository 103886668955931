import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import FeaturedBlock from "../components/featured-block";
import Projects from "../components/creating-projects";
import CreatingFeaturedBlock from "../components/creating-featured-block";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CreatingPageQuery {
    creating: sanityCreating {
      featuredBlock {
        heading
        description
        image {
          ...SanityImage
          alt
        }
      }
      projectsHeading
      creatingFeaturedBlock {
        _rawHeading
        topSubheading
        bottomSubheading
        description
        image {
          ...SanityImage
          alt
        }
        link {
          text
          linkUrl
          externalContent
        }
      }
    }
  }
`;

const CreatingPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const creating = (data || {}).creating;

  return (
    <Layout>
      <Seo
        title={creating.featuredBlock.heading}
        description={creating.featuredBlock.description}
      />
      
      {creating.featuredBlock && (
        <FeaturedBlock {...creating.featuredBlock} />
      )}

      <Projects title={creating.projectsHeading} />

      {creating.creatingFeaturedBlock && (
        <CreatingFeaturedBlock {...creating.creatingFeaturedBlock} />
      )}
      
    </Layout>
  );
};

export default CreatingPage;
